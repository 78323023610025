import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {
  getAllCards,
  updateSelectedCard,
  addCard,
  deleteCard,
} from '../../redux/actions'
import {Table} from './Table'
import TopContainerComponent from './TopContainer'
import {Modal} from '../../components/Modal'
import {AddCard} from './AddCard'
import styled from 'styled-components'
import {FilterTags} from './FilterTags'
import {Search} from '../../components/Search'
import Loading from '../../components/LoadingOrange'

const CreditCard = ({
  cards,
  getAllCards,
  selectedCard = null,
  userInfo,
  locationId,
  addCard,
  updateSelectedCard,
  loading,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [filterValue, setFilterValue] = useState('all')
  const [searchTerm, setSearchTerm] = useState('')
  const showTopContainer = !selectedCard

  useEffect(() => {
    const fetchData = async () => {
      await getAllCards()
    }

    fetchData()
  }, [getAllCards])

  useEffect(() => {
    updateSelectedCard(null)
    setModalOpen(false)
  }, [])

  const handleCardClick = (cardId) => {
    updateSelectedCard(cardId)
  }

  const closeAddCardModal = () => {
    setModalOpen(false)
  }

  return (
    <Container id="credit-cards">
      {!loading && cards && cards.length > 0 && (
        <>
          <TopContainerComponent
            selectedCard={selectedCard}
            handleCardClick={handleCardClick}
            setModalOpen={setModalOpen}
            setFilterValue={setFilterValue}
            showTopContainer={showTopContainer}
          />
          {showTopContainer && (
            <SearchAndFilterContainer>
              <Search
                value={searchTerm}
                onChange={setSearchTerm}
                placeholder="Search customer..."
                ariaLabel="Search customers"
              />
              <FilterTags
                selectedFilter={filterValue}
                onFilterChange={setFilterValue}
              />
            </SearchAndFilterContainer>
          )}
          <Table
            cards={cards}
            selectedCard={selectedCard}
            handleCardClick={handleCardClick}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </>
      )}
      <Modal isOpen={modalOpen}>
        <AddCard
          addCard={addCard}
          userInfo={userInfo}
          locationId={locationId}
          closeAddCardModal={closeAddCardModal}
        />
      </Modal>
      <Loading open={loading} />
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.user?.userInfo || {},
    cards: state.cards?.customers?.sort((card1, card2) => {
      const a = (card1.given_name + card1.family_name).toLowerCase()
      const b = (card2.given_name + card2.family_name).toLowerCase()
      return a < b ? -1 : 1
    }),
    selectedCard: state.cards.selectedCard,
    loading: state.app.loading,
    locationId: state.user?.store?.squareLocationId || null,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllCards: (payload) => dispatch(getAllCards(payload)),
  updateSelectedCard: (cardId) => dispatch(updateSelectedCard(cardId)),
  addCard: (payload) => dispatch(addCard(payload)),
  deleteCard: (payload) => dispatch(deleteCard(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 4rem 1rem;
  }
  padding: 4rem 14rem;
  & > *:not(:last-child) {
    margin-bottom: 1.6rem;
  }
`

const SearchAndFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
`

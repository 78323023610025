import {combineReducers} from 'redux'

import user from './user'
import deliveries from './deliveries'
import payment from './payment'
import app from './app'
import admin from './admin'
import orders from './orders'
import cards from './cards'
import voice from './voice'
import address from './address'

const appReducer = combineReducers({
  user,
  deliveries,
  payment,
  app,
  admin,
  orders,
  cards,
  voice,
  address,
})

export default appReducer

import React from 'react'
import styled from 'styled-components'

const TIER_DATA = [
  {
    plan: 'Starter',
    monthlyPrice: 30,
    pricePerStop: 1.0,
    stopsFrom: 1,
    stopsTo: 100,
  },
  {
    plan: 'Basic',
    monthlyPrice: 30,
    pricePerStop: 0.75,
    stopsFrom: 101,
    stopsTo: 300,
  },
  {
    plan: 'Professional',
    monthlyPrice: 30,
    pricePerStop: 0.6,
    stopsFrom: 301,
    stopsTo: 500,
  },
  {
    plan: 'Premium',
    monthlyPrice: 30,
    pricePerStop: 0.5,
    stopsFrom: 501,
    stopsTo: 1000,
  },
]

export const TierTable = ({tier, setTier}) => {
  const handleCustomTierChange = (field, value) => {
    setTier((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <th>Plan</th>
            <th>Monthly</th>
            <th>Per Stop</th>
            <th>From Stops</th>
            <th>To Stops</th>
          </tr>
        </thead>
        <tbody>
          {TIER_DATA.map((tier, index) => (
            <tr key={`tier-${index}`}>
              <td>{tier.plan}</td>
              <td>CAD${tier.monthlyPrice.toFixed(2)}</td>
              <td>CAD${tier.pricePerStop.toFixed(2)}</td>
              <td>{tier.stopsFrom}</td>
              <td>{tier.stopsTo}</td>
            </tr>
          ))}
          <tr>
            <td>Custom</td>
            <td>
              <input
                type="number"
                value={tier.monthlyPrice}
                onChange={(e) =>
                  handleCustomTierChange('monthlyPrice', e.target.value)
                }
                placeholder="CAD$0.00"
              />
            </td>
            <td>
              <input
                type="number"
                value={tier.pricePerStop}
                onChange={(e) =>
                  handleCustomTierChange('pricePerStop', e.target.value)
                }
                placeholder="CAD$0.00"
              />
            </td>
            <td>
              <input
                type="number"
                value={tier.stopsFrom}
                onChange={(e) =>
                  handleCustomTierChange('stopsFrom', e.target.value)
                }
                placeholder="e.g., 1001"
              />
            </td>
            <td>
              <input
                type="number"
                value={tier.stopsTo}
                onChange={(e) =>
                  handleCustomTierChange('stopsTo', e.target.value)
                }
                placeholder="e.g., 2000"
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </TableContainer>
  )
}

const TableContainer = styled.div`
  margin-top: 2rem;
  overflow-x: auto;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 12px;
    text-align: left;
    border: 1px solid ${({theme}) => theme.colors.border};
  }

  th {
    background-color: ${({theme}) => theme.colors.background};
    font-weight: 500;
  }

  input {
    width: 100%;
    padding: 8px;
    border: 1px solid ${({theme}) => theme.colors.border};
    border-radius: 4px;

    &:focus {
      outline: none;
      border-color: ${({theme}) => theme.colors.primary};
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
    }
  }
`

import React, {useState} from 'react'
import styled from 'styled-components'
import {InputLabel} from '@material-ui/core'
import theme from '../../../theme'
import {TierTable} from './TierTable'

const SOFTWARE_MODELS = ['Legacy', 'Tiered']

export const InvoiceDataForm = ({
  softwareModel,
  setSoftwareModel,
  tier,
  setTier,
}) => {
  return (
    <div>
      <StyledLabel>Software Model</StyledLabel>
      <select
        name="Software Model"
        style={{
          ...dropdownStyle,
          width: '100%',
          padding: '18.5px 14px',
        }}
        required
        onChange={(e) => setSoftwareModel(e.target.value)}
        value={softwareModel}>
        {SOFTWARE_MODELS.map((item, index) => (
          <option key={`software-model-option-${index}`} value={item}>
            {item}
          </option>
        ))}
      </select>
      {softwareModel === 'Tiered' && (
        <TierTable tier={tier} setTier={setTier} />
      )}
    </div>
  )
}

const StyledLabel = styled(InputLabel)`
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.label};
  line-height: 2rem;
`

const dropdownStyle = {
  padding: '14px 12px',
  width: '300px',
  border: '1px solid #E0E0E0',
  color: theme.colors.label,
  borderRadius: '8px',
  backgroundColor: '#FFFFFF',
  fontSize: '16px',
  cursor: 'pointer',
  appearance: 'none',
  backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1L7 7L13 1' stroke='%23666666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e")`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 16px center',
  transition: 'border-color 0.2s ease',
  '&:hover': {
    borderColor: '#C4D5EE',
  },
  '&:focus': {
    outline: 'none',
    borderColor: theme.colors.primary,
    boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.1)',
  },
}

export const isValidPostalCode = (postalCode) => {
  const canadianPostalCodeRegex =
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]-\d[ABCEGHJ-NPRSTV-Z]\d$/i
  return canadianPostalCodeRegex.test(postalCode)
}

export const isFormValid = ({
  storeNumber,
  storeName,
  streetAddress,
  storePhone,
  city,
  postalCode,
  state,
  firstName,
  lastName,
  isScriptRunnerCard,
  email,
  programId,
  driverModel,
  flatRate,
  distanceRates,
  costAddedPercentage,
  softwareModel,
  tier,
  setupFees,
  showSetupFees,
}) => {
  if (
    !storeNumber ||
    !storeName ||
    !streetAddress ||
    !storePhone ||
    !city ||
    !postalCode ||
    !state ||
    !firstName ||
    !lastName
  ) {
    return false
  }

  if (!isValidPostalCode(postalCode)) {
    return false
  }

  if (isScriptRunnerCard && (!email || !programId)) {
    return false
  }

  if (!driverModel) {
    return false
  }

  switch (driverModel) {
    case 'Flat Rate':
      if (!flatRate) return false
      break
    case 'Distance Based':
      if (!distanceRates.length) return false
      if (
        distanceRates.some(
          (rate) => !rate.distanceFrom || !rate.distanceTo || !rate.rate,
        )
      )
        return false
      break
    case 'Cost Added':
      if (!costAddedPercentage) return false
      break
    default:
      return false
  }

  if (softwareModel === 'Tiered') {
    if (
      !tier.monthlyPrice ||
      !tier.pricePerStop ||
      !tier.stopsFrom ||
      !tier.stopsTo
    ) {
      return false
    }
  }

  if (showSetupFees && setupFees.length > 0) {
    const hasIncompleteFees = setupFees.some(
      (fee) => (fee.date && !fee.amount) || (!fee.date && fee.amount),
    )
    if (hasIncompleteFees) {
      return false
    }
  }

  return true
}

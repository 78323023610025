import React from 'react'
import styled from 'styled-components'

const DestinationCard = ({googlePlaceId, address, isSelected, onSelect}) => (
  <DestinationCardContainer
    key={googlePlaceId}
    selected={isSelected}
    onClick={() => onSelect(googlePlaceId)}
    role="button"
    aria-pressed={isSelected}
    tabIndex={0}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onSelect(googlePlaceId)
      }
    }}>
    <AddressContainer>
      <h5>{address}</h5>
    </AddressContainer>
  </DestinationCardContainer>
)

const DestinationCardContainer = styled.div`
  position: relative;
  background: white;
  border-radius: 12px;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid
    ${({selected, theme}) => (selected ? theme.colors.primary : 'transparent')};
  cursor: pointer;
  width: 100%;
  min-height: 55px;
  max-height: 20%;
  transition: all 0.2s ease-in-out;
  padding: 10px;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.1);
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${({theme}) => `${theme.colors.primary}40`};
  }
`

const AddressContainer = styled.div`
  margin: 10px 0px;
  width: 100%;
  h5 {
    font-size: 13px;
    margin: 0 0 4px 0;
  }

  p {
    margin: 4px 0;
    color: ${({theme}) => theme.colors.label};
  }
`

export default DestinationCard

import {
  GET_ADDRESS_VALIDATION,
  GET_ADDRESS_VALIDATION_SUCCESS,
  RESET_ADDRESS_VALIDATION,
  START_LOADING,
  STOP_LOADING,
} from '../actions/types'

function addressValidationDataReducer(state = {destinations: []}, action = {}) {
  switch (action.type) {
    case GET_ADDRESS_VALIDATION: {
      return {...state, destinations: []}
    }
    case GET_ADDRESS_VALIDATION_SUCCESS: {
      return {
        ...state,
        destinations: action.payload?.destinations || [],
        length: action.payload?.length || 0,
      }
    }
    case RESET_ADDRESS_VALIDATION:
      return {destinations: [], length: 0, selected: {}}
    case START_LOADING:
      return {...state, loading: true}
    case STOP_LOADING:
      return {...state, loading: false}
    default:
      return state
  }
}

export default addressValidationDataReducer

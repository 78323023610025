import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {InputLabel} from '@material-ui/core'
import {SecondaryButton, DangerButton} from '../../../components/Button'

export const SetupFeeForm = ({
  setupFees,
  setSetupFees,
  showSetupFees,
  setShowSetupFees,
}) => {
  const [errors, setErrors] = useState([])

  useEffect(() => {
    const newErrors = setupFees.map((fee) => {
      if ((fee.amount && !fee.date) || (!fee.amount && fee.date)) {
        return 'Both date and amount are required when either is provided'
      }
      return ''
    })
    setErrors(newErrors)
  }, [setupFees])

  const handleAddSetupFee = () => {
    setSetupFees([...setupFees, {date: '', amount: ''}])
  }

  const handleSetupFeeChange = (index, field, value) => {
    const newFees = [...setupFees]
    newFees[index][field] = value
    setSetupFees(newFees)
  }

  const handleRemoveSetupFee = (index) => {
    setSetupFees(setupFees.filter((_, i) => i !== index))
    setErrors(errors.filter((_, i) => i !== index))
  }

  return (
    <Container>
      {!showSetupFees ? (
        <SecondaryButton onClick={() => setShowSetupFees(true)}>
          Configure Setup Fees
        </SecondaryButton>
      ) : (
        <>
          <StyledLabel>One-Time Setup Fees</StyledLabel>
          <FeesContainer>
            {setupFees.map((fee, index) => (
              <div key={index}>
                <FeeRow>
                  <FeeField>
                    <Input
                      type="date"
                      value={fee.date}
                      onChange={(e) =>
                        handleSetupFeeChange(index, 'date', e.target.value)
                      }
                    />
                  </FeeField>
                  <FeeField>
                    <Input
                      type="number"
                      value={fee.amount}
                      onChange={(e) =>
                        handleSetupFeeChange(index, 'amount', e.target.value)
                      }
                      placeholder="CAD$0.00"
                    />
                  </FeeField>
                  <DangerButton
                    style={{
                      width: '150px',
                      height: '60px',
                      fontWeight: 'bold',
                      backgroundColor: 'transparent',
                      border: '1px solid #FF4D4F',
                      color: '#FF4D4F',
                    }}
                    onClick={() => handleRemoveSetupFee(index)}>
                    Remove
                  </DangerButton>
                </FeeRow>
                {errors[index] && <ErrorMessage>{errors[index]}</ErrorMessage>}
              </div>
            ))}
            <SecondaryButton onClick={handleAddSetupFee}>
              Add Setup Fee
            </SecondaryButton>
          </FeesContainer>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  margin-top: 2rem;
`

const FeesContainer = styled.div`
  margin-top: 1rem;
`

const FeeRow = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
`

const FeeField = styled.div`
  flex: 1;
`

const Input = styled.input`
  width: 100%;
  padding: 18.5px 14px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: ${({theme}) => theme.colors.primary};
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
  }
`

const StyledLabel = styled(InputLabel)`
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.label};
  line-height: 2rem;
`

const ErrorMessage = styled.div`
  color: ${({theme}) => theme.colors.error || '#dc3545'};
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 8px;
`

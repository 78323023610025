import React from 'react'
import styled from 'styled-components'
import get from 'lodash.get'

import {withStyles, makeStyles, useTheme} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Card from '@material-ui/core/Card'

import {CustomIconButton} from '../CustomIconButton'

import {isDateProp, formatDate, generalFormatDate} from '../../utils/dates'
import {getOrderStatusStep, formatName} from '../../utils/common'
import {DeleteIcon, EditIcon, MoreIcon} from '../Icons'
import {OrderDetailList} from '../OrderDetailList'
import StatusInfo from '../StatusInfo'

import TableHeadCell from './TableHeadCell'
import {Checkbox} from '@material-ui/core'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.colors.background,
    color: theme.colors.label,
    border: 'none',
    height: '4.2rem',
    paddingTop: 0,
    paddingBottom: 0,
  },
  body: {
    backgroundColor: (props) =>
      props.isRefrigerated
        ? 'rgb(223 239 255)'
        : theme.colors.secondaryBackground,
    fontSize: 14,
    verticalAlign: 'middle',
    '&:not(first-child)': {
      borderWidth: '1px 0',
      borderColor: theme.colors.stroke,
      borderStyle: 'solid',
    },
    '&:first-child': {
      borderWidth: '1px 0 1px 1px',
      borderRadius: '5px 0 0 5px',
      borderColor: theme.colors.stroke,
      borderStyle: 'solid',
    },
    '&:last-child': {
      borderWidth: '1px 1px 1px 0',
      borderRadius: '0 5px 5px 0',
      borderColor: theme.colors.stroke,
      borderStyle: 'solid',
    },
  },
}))(TableCell)

const Container = styled.div`
  margin-top: ${({marginTop = '2.3rem'}) => marginTop};
  width: 100%;
`

const ActionsContainer = styled.div`
  display: flex;
`

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 4px',
  },
  tableContainer: {
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableHead: {
    color: theme.colors.label,
    border: 'none',
    height: '4.7rem',
  },
  row: {
    margin: '0 15px',
    verticalAlign: 'top',
  },
  cardContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: `0px 4px 21px ${theme.card.shadow}`,
    borderRadius: '5px',
    padding: '15px 48px',
    marginTop: '28px',
  },
  actionIconLeft: {
    fill: `${theme.colors.iconfill}`,
    margin: ' 0 6px 0 0',
  },
  actionCell: {
    display: 'flex',
    '& svg': {
      marginRight: 5,
    },
  },
}))

const getMultiplePropCell = (
  multiKey,
  index,
  borderColor,
  dates,
  row,
  deliveryContainsNarcotics,
  handleCheckboxChange,
  selectedOrders,
) => {
  const key = Array.isArray(multiKey) ? multiKey[0] : multiKey
  switch (key) {
    case 'detail':
      return (
        <StyledTableCell
          key={`prop-${index}-${key}`}
          align="left"
          isRefrigerated={hasRefrigeratedItems(row)}
          style={{borderColor}}>
          <OrderDetailList detail={row[key]} />
        </StyledTableCell>
      )
    case 'customerName':
      return (
        <StyledTableCell
          key={`prop-${index}-${key}`}
          align="left"
          isRefrigerated={hasRefrigeratedItems(row)}
          style={{borderColor}}>
          {formatName(row[key] ?? '')}
        </StyledTableCell>
      )
    case 'orderStatus':
      return (
        <StyledTableCell
          key={`prop-${index}-${key}`}
          align="left"
          isRefrigerated={hasRefrigeratedItems(row)}
          style={{borderColor}}>
          <StatusInfo order={row} isNarcotics={deliveryContainsNarcotics} />
        </StyledTableCell>
      )
    case 'deliveryStatus':
      return (
        <StyledTableCell
          key={`prop-${index}-${key}`}
          align="left"
          isRefrigerated={hasRefrigeratedItems(row)}
          style={{borderColor}}>
          <StatusInfo order={row} isDelivery />
        </StyledTableCell>
      )
    case 'paymentType':
      return (
        <StyledTableCell
          key={`prop-${index}-${key}`}
          align="left"
          isRefrigerated={hasRefrigeratedItems(row)}
          style={{borderColor}}>
          <>
            {getPaymentMessage(row, key)}
            <span>
              {row[key] === 'SR_CARD' && row.paymentTotal !== null
                ? `$${row.paymentTotal} (ScriptRunner Card)`
                : '$0.00'}
            </span>
          </>
        </StyledTableCell>
      )
    case 'select':
      return (
        <StyledTableCell
          key={`prop-${index}-${key}`}
          align="left"
          isRefrigerated={hasRefrigeratedItems(row)}
          style={{borderColor}}>
          <Checkbox
            checked={selectedOrders.includes(row.orderId)}
            onChange={() => handleCheckboxChange(row.orderId)}
          />
        </StyledTableCell>
      )
    default:
      return (
        <StyledTableCell
          key={`prop-${index}-${key}`}
          align="left"
          isRefrigerated={hasRefrigeratedItems(row)}
          style={{borderColor}}>
          {isDateProp(dates, key)
            ? formatDate(generalFormatDate(row[key]))
            : row[key]}
        </StyledTableCell>
      )
  }
}

export const getPaymentMessage = (row, key, isModal = false) => {
  // Handle card on file cases
  if (row.customerCreditCard && row.customerCreditCard !== '') {
    const isDeclined =
      !row.payment_id && row.paymentTotal !== null && row.paymentTotal > 0.0
    return [
      <span
        key="cardOnFile"
        style={isDeclined && !isModal ? {color: 'red'} : undefined}>
        {`Card on File${isDeclined && !isModal ? ' (DECLINED)' : ''}`}
      </span>,
      <br key="br" />,
    ]
  }
  if (row[key] !== 'SR_CARD' || row.paymentTotal === null) {
    return ''
  }
  // Handle pay at door case
  return [<span key="payAtDoor">Pay at Door</span>, <br key="br" />]
}
// Function to check if the row has any refrigerated items
const hasRefrigeratedItems = (row) => {
  return row.detail?.some((item) => item.isRefrigerated)
}

const CustomTable = ({
  headers = [],
  rows = [],
  children,
  properties = [],
  dates = [],
  keyProp,
  marginTop = '2.3rem',
  isDeliveryHistory = false,
  onClick = () => {},
  primaryAction = () => {},
  secondaryAction = () => {},
  moreIconClick = () => {},
  handleCheckboxChange,
  selectedOrders,
  lastElementRef,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isOrderEditable = (order) => {
    const orderStatus = get(order, 'orderStatus', '')
    const orderStep = getOrderStatusStep(orderStatus)
    return orderStep < getOrderStatusStep('DROPOFF_PENDING')
  }
  const doesDeliveryContainNarcotics = (row) =>
    row.detail && row.detail.some((order) => order?.isNarcotics)

  const sortedRows = [...rows].sort((a, b) => {
    return b.orderId - a.orderId
  })

  return (
    <Container marginTop={marginTop}>
      <TableContainer className={classes.tableContainer}>
        <Table
          id="table"
          stickyHeader
          className={classes.table}
          aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {headers.map((header) => {
                const width = isDeliveryHistory ? `${header.width}%` : undefined
                return (
                  <StyledTableCell
                    align="left"
                    key={header.label}
                    style={{width}}>
                    <TableHeadCell header={header}>
                      {header.label}
                    </TableHeadCell>
                  </StyledTableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!sortedRows.length &&
              sortedRows.map((row, rowIndex) => {
                const borderColor =
                  row.outstandingAmount > 0
                    ? theme.colors.error
                    : theme.colors.stroke
                const isEditable = isOrderEditable(row)
                const deliveryContainsNarcotics =
                  doesDeliveryContainNarcotics(row)
                let rowStyle = {cursor: 'auto'}
                if (hasRefrigeratedItems(row)) rowStyle.backgroundColor = 'blue'
                if (isDeliveryHistory) rowStyle.cursor = 'pointer'
                return (
                  <TableRow
                    style={rowStyle}
                    className={classes.row}
                    key={`${row}-${rowIndex}`}
                    ref={
                      lastElementRef && rowIndex === rows.length - 1
                        ? lastElementRef
                        : null
                    }
                    data-index={`delivery-${rowIndex}`}
                    rowid={row[keyProp]}
                    onClick={(e) => {
                      onClick(e.currentTarget.getAttribute('rowid'))
                    }}>
                    {properties.map((key, index) => {
                      if (key === 'info' && !isEditable) {
                        return (
                          <StyledTableCell
                            key={`prop-${index}-${key}`}
                            align="left"
                            style={{borderColor}}
                            isRefrigerated={hasRefrigeratedItems(row)}
                            onClick={(e) => moreIconClick(row[keyProp])}>
                            <MoreIcon />
                          </StyledTableCell>
                        )
                      }
                      return key === 'actions' ? (
                        <StyledTableCell
                          key={`prop-${index}-${key}`}
                          align="left"
                          isRefrigerated={hasRefrigeratedItems(row)}
                          style={{borderColor}}>
                          {isEditable ? (
                            <ActionsContainer>
                              <CustomIconButton
                                style={{width: '40px', height: '40px'}}
                                id={`edit-${rowIndex}-button`}
                                rowid={row[keyProp]}
                                onClick={(e) => {
                                  primaryAction(
                                    e.currentTarget.getAttribute('rowid'),
                                    isEditable,
                                  )
                                }}>
                                <EditIcon className={classes.actionIconLeft} />
                              </CustomIconButton>
                              <CustomIconButton
                                style={{width: '40px', height: '40px'}}
                                id={`delete-${rowIndex}-button`}
                                rowid={row[keyProp]}
                                onClick={(e) => {
                                  secondaryAction(
                                    e.currentTarget.getAttribute('rowid'),
                                  )
                                }}>
                                <DeleteIcon />
                              </CustomIconButton>
                              {/* {hasRefrigeratedItems(row) && (
                                <div
                                  style={{
                                    height: '40px',
                                    width: '40px',
                                    textAlign: 'center',
                                  }}>
                                  <AcUnitIcon />
                                </div>
                              )} */}
                            </ActionsContainer>
                          ) : (
                            ''
                          )}
                        </StyledTableCell>
                      ) : (
                        getMultiplePropCell(
                          key,
                          index,
                          borderColor,
                          dates,
                          row,
                          deliveryContainsNarcotics,
                          handleCheckboxChange,
                          selectedOrders,
                        )
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {children ? (
        <Card className={classes.cardContainer}>{children}</Card>
      ) : (
        <></>
      )}
    </Container>
  )
}

export default CustomTable

import React, {useState, useEffect} from 'react'
import {InputLabel} from '@material-ui/core'
import styled from 'styled-components'

export const RecurringFees = ({
  recurringFeeAmount,
  setRecurringFeeAmount,
  recurringFeeFrequency,
  setRecurringFeeFrequency,
  setHasRecurringFeeError,
}) => {
  const [error, setError] = useState('')

  useEffect(() => {
    const hasError = Boolean(recurringFeeAmount && !recurringFeeFrequency)
    setError(
      hasError
        ? 'Please select a frequency when entering a recurring fee amount'
        : '',
    )
    setHasRecurringFeeError(hasError)
  }, [recurringFeeAmount, recurringFeeFrequency, setHasRecurringFeeError])

  const handleAmountChange = (e) => {
    const newAmount = e.target.value
    setRecurringFeeAmount(newAmount)

    if (!newAmount) {
      setRecurringFeeFrequency('')
    }
  }

  return (
    <>
      <StyledLabel>Recurring Fee Amount</StyledLabel>
      <Container>
        <select
          name="recurringFeeFrequency"
          id="recurringFeeFrequency"
          value={recurringFeeFrequency}
          onChange={(e) => setRecurringFeeFrequency(e.target.value)}
          style={dropdownStyle}
          required={Boolean(recurringFeeAmount)}>
          <option value="">Select Frequency</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>

        <Input
          type="number"
          value={recurringFeeAmount}
          onChange={handleAmountChange}
          placeholder="CAD$0.00"
        />
      </Container>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  )
}

const Container = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`

const dropdownStyle = {
  padding: '18.5px 14px',
  width: '100%',
  border: '1px solid #E0E0E0',
  borderRadius: '8px',
  backgroundColor: '#FFFFFF',
  fontSize: '16px',
  cursor: 'pointer',
  appearance: 'none',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 16px center',
}

const StyledLabel = styled(InputLabel)`
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.label};
  line-height: 2rem;
`

const Input = styled.input`
  width: 100%;
  padding: 18.5px 14px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: ${({theme}) => theme.colors.primary};
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
  }
`

const ErrorMessage = styled.div`
  color: ${({theme}) => theme.colors.error || '#dc3545'};
  font-size: 14px;
`

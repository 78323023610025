import {put, takeEvery, select, throttle} from 'redux-saga/effects'
import get from 'lodash.get'

import {getToken} from '../utils'
import {request} from '../../utils/api'
import {apiFormatDate} from '../../utils/dates'

import {
  ADD_DELIVERY,
  GET_DELIVERIES,
  GET_DELIVERY,
  CANCEL_DELIVERY,
  UPDATE_DELIVERY,
  START_LOADING,
  STOP_LOADING,
  ADD_RETURNED_ORDERS,
  GET_ONHOLD_ORDERS,
  ADD_ONHOLD_ORDERS,
  REMOVE_ONHOLD_ORDER,
  DUPLICATE_DELIVERY,
  DELETE_DELIVERY,
} from '../actions/types'
import {
  addDeliverySuccess,
  getDeliveriesSuccess,
  getDeliveriesFail,
  getDeliverySuccess,
  deleteDeliverySuccess,
  getOnHoldOrdersFail,
  getOnHoldOrdersSuccess,
  cancelDeliverySuccess,
  showError,
  resetDeliveryFilters,
  duplicateDeliverySuccess,
  duplicateDeliveryFail,
  noMoreDeliveries,
} from '../actions'

import {
  API_DELIVERIES_ENDPOINT,
  API_ONHOLD_ORDERS_ENDPOINT,
  API_REMOVE_ONHOLD_ENDPOINT,
  API_RETURNS_ENDPOINT,
  API_THROTTLE_TIME_MS,
} from '../../constants'
import {composeDeliveryFilters} from '../../utils/common'
import {DELIVERIES_FETCH_LIMIT} from '../../utils/constants'

export function* addDelivery({payload: {history}}) {
  const body = {deliveryDate: apiFormatDate()}
  const token = yield select(getToken)
  const reqOpt = {
    method: 'POST',
    url: API_DELIVERIES_ENDPOINT,
    token,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const delivery = yield request(reqOpt)
    yield put(addDeliverySuccess(delivery))
    yield put({type: STOP_LOADING})
    yield put(resetDeliveryFilters())
    history.push(`/dashboard/delivery/${get(delivery, 'deliveryId', '')}`)
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* getDeliveries({payload}) {
  const filters = composeDeliveryFilters(payload)
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: API_DELIVERIES_ENDPOINT,
    token,
    filters,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const deliveries = yield request(reqOpt)
    yield put(getDeliveriesSuccess(deliveries))
    if (deliveries.length < DELIVERIES_FETCH_LIMIT) {
      yield put(noMoreDeliveries())
    }
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(getDeliveriesFail(error))
    yield put(showError(error))
  }
}

export function* duplicateDelivery({payload: {history, payload}}) {
  const token = yield select(getToken)
  // const history = useHistory()
  const reqOpt = {
    method: 'POST',
    url: `${API_DELIVERIES_ENDPOINT}/duplicate`,
    token,
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const newDelivery = yield request(reqOpt)
    yield put(duplicateDeliverySuccess(newDelivery))
    yield put({type: STOP_LOADING})
    history.push('/dashboard')
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(duplicateDeliveryFail(error))
    yield put(showError(error))
  }
}

export function* getDelivery({payload: {deliveryId}}) {
  const token = yield select(getToken)

  const reqOpt = {
    method: 'GET',
    url: `${API_DELIVERIES_ENDPOINT}/${deliveryId}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const delivery = yield request(reqOpt)
    yield put(getDeliverySuccess(delivery))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* deleteDelivery({payload: {history, deliveryId}}) {
  console.log('deleteDelivery saga started with deliveryId:', deliveryId)
  const token = yield select(getToken)

  const reqOpt = {
    method: 'DELETE',
    url: `${API_DELIVERIES_ENDPOINT}/${deliveryId}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    console.log('Making DELETE request to API')
    yield request(reqOpt)
    yield put(deleteDeliverySuccess())
    console.log('Dispatched deleteDeliverySuccess action')
    yield put({type: STOP_LOADING})
    history.push('/dashboard')
  } catch (error) {
    console.error('Error in deleteDelivery saga:', error)
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* cancelDelivery({payload: {history, deliveryId}}) {
  const token = yield select(getToken)

  const reqOpt = {
    method: 'DELETE',
    url: `${API_DELIVERIES_ENDPOINT}/${deliveryId}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    yield request(reqOpt)
    yield put(cancelDeliverySuccess())
    yield put({type: STOP_LOADING})
    history.push(`/dashboard/home`)
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* updateDelivery({payload: {delivery, deliveryId, successCb}}) {
  const body = {
    ...delivery,
    deliveryDate: apiFormatDate(delivery.deliveryDate),
  }
  const token = yield select(getToken)
  const reqOpt = {
    method: 'PUT',
    url: `${API_DELIVERIES_ENDPOINT}/${deliveryId}`,
    token,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})

    yield request(reqOpt)

    if (successCb) {
      successCb()
    }

    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put(showError(error))
    yield put({type: STOP_LOADING})
  }
}

export function* addReturnedOrders({payload}) {
  const formattedReturns = payload.data.orders.map((item) => {
    delete item.deliveryDate
    return item
  })

  const token = yield select(getToken)
  const reqOpt = {
    method: 'PUT',
    url: `${API_RETURNS_ENDPOINT}`,
    token,
    body: JSON.stringify({
      newDeliveryId: payload.data.deliveryId,
      addOrders: formattedReturns,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    yield request(reqOpt)
    yield put({
      type: GET_DELIVERY,
      payload: {deliveryId: payload.data.deliveryId},
    })
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* getOnHoldOrders({payload}) {
  const {ordersDate} = payload
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: API_ONHOLD_ORDERS_ENDPOINT,
    token,
    filters: {ordersDate},
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const returnedOrders = yield request(reqOpt)
    yield put(getOnHoldOrdersSuccess(returnedOrders))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(getOnHoldOrdersFail(error))
    yield put(showError(error))
  }
}

export function* addOnHoldOrders({payload}) {
  const {deliveryId, orders} = payload.data

  const token = yield select(getToken)
  const reqOpt = {
    method: 'PUT',
    url: `${API_ONHOLD_ORDERS_ENDPOINT}`,
    token,
    body: JSON.stringify({
      newDeliveryId: deliveryId,
      addOrders: orders,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    yield request(reqOpt)
    yield put({
      type: GET_DELIVERY,
      payload: {deliveryId: payload.data.deliveryId},
    })
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* removeOnHoldOrder({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'PUT',
    url: API_REMOVE_ONHOLD_ENDPOINT,
    token,
    body: JSON.stringify({
      removeOrders: payload.removeOrders,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    yield request(reqOpt)
    yield put({type: STOP_LOADING})
    payload.callback && payload.callback()
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* watchDeliveries() {
  yield takeEvery(ADD_DELIVERY, addDelivery)
  yield throttle(API_THROTTLE_TIME_MS, GET_DELIVERIES, getDeliveries)
  yield throttle(API_THROTTLE_TIME_MS, GET_ONHOLD_ORDERS, getOnHoldOrders)
  yield throttle(API_THROTTLE_TIME_MS, GET_DELIVERY, getDelivery)
  yield takeEvery(CANCEL_DELIVERY, cancelDelivery)
  yield takeEvery(REMOVE_ONHOLD_ORDER, removeOnHoldOrder)
  yield takeEvery(UPDATE_DELIVERY, updateDelivery)
  yield takeEvery(ADD_RETURNED_ORDERS, addReturnedOrders)
  yield takeEvery(ADD_ONHOLD_ORDERS, addOnHoldOrders)
  yield takeEvery(DUPLICATE_DELIVERY, duplicateDelivery)
  yield takeEvery(DELETE_DELIVERY, deleteDelivery)
}

export default watchDeliveries

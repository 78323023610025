import {InputLabel, Modal, Button} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {PrimaryButton} from '../../components/Button'
import Loading from '../../components/LoadingOrange'
import TextField from '../../components/Textfield'
import {ADMIN_EMAILS, ADMIN_TABS, PROVINCES} from '../../constants'
import DateFilter from '../../containers/DateFilter'
import {
  adminFetchStore,
  createPharmacy,
  editAdminFetchStore,
  fetchDrivers,
  fetchStores,
  addDriver,
  deleteDrivers,
} from '../../redux/actions'
import theme from '../../theme'
import {Trash, Add} from 'iconsax-react'
import {IoCloseOutline} from 'react-icons/io5'
import {SecondaryDangerButton} from '../../components/Button'
import {CreateDriverForm} from './CreateDriverForm'
import {DriverCard} from './DriverCard'
import {Search} from '../../components/Search'
import {InvoiceDataForm} from './StoreForm/InvoiceDataForm'
import {DriverModelForm} from './StoreForm/DriverModelForm'
import {SetupFeeForm} from './StoreForm/SetupFeeForm'
import {RecurringFees} from './StoreForm/RecurringFees'
import {isValidPostalCode, isFormValid} from './utils'

const ErrorMessage = styled.div`
  color: ${theme.colors.error || '#ff0000'};
  font-size: 12px;
  margin-top: 4px;
  min-height: 16px;
`

const Admin = ({
  loading,
  createPharmacy,
  currentAdminPage,
  drivers = [],
  stores = [],
  fetchDrivers,
  fetchStores,
  userInfo,
  adminFetchStore,
  editAdminFetchStore,
  downloadingReport,
  addDriver,
  deleteDrivers,
}) => {
  const onSubmit = (e) => {
    e.preventDefault()
    const formattedPostalCode = postalCode
      .replace(/[^A-Za-z0-9]/g, '')
      .toUpperCase()
      .replace(/^(.{3})(.{3})$/, '$1-$2')

    const data = {
      storeNumber,
      storeName,
      streetAddress,
      storePhone,
      firstName,
      lastName,
      postalCode: formattedPostalCode,
      state,
      city,
      programId: Number(programId),
      email,
      createSrCard: isScriptRunnerCard,
      isBarcodeScanningRequired,
      isNarcoticsCheckRequired,
      allowSplitDropoffs,
      enableCustomerMatching: customerProfileMatch,
      driverModel,
      flatRate: driverModel === 'Flat Rate' ? Number(flatRate) : null,
      distanceRates:
        driverModel === 'Distance Based'
          ? distanceRates.map((rate, index) => ({
              distanceFrom: Number(rate.distanceFrom),
              distanceTo: Number(rate.distanceTo),
              rate: Number(rate.rate),
              sortOrder: index,
            }))
          : null,
      costAddedPercentage:
        driverModel === 'Cost Added' ? Number(costAddedPercentage) : null,
      setupFees: showSetupFees
        ? setupFees
            .filter((fee) => fee.date && fee.amount)
            .map((fee) => ({
              amount: Number(fee.amount),
              date: fee.date,
            }))
        : [],
      recurringFee: recurringFeeAmount
        ? {
            frequency: recurringFeeFrequency,
            amount: Number(recurringFeeAmount),
          }
        : null,
      softwareModel,
      tier:
        softwareModel === 'Tiered' && tier
          ? {
              plan: 'Custom',
              stopsFrom: Number(tier.stopsFrom),
              stopsTo: Number(tier.stopsTo),
              monthlyPrice: Number(tier.monthlyPrice),
              pricePerStop: Number(tier.pricePerStop),
            }
          : null,
      additionalEmailForInvoice: additionalEmailForInvoice || null,
      additionalEmailForCashRecovery: additionalEmailForCashRecovery || null,
    }

    isEdit
      ? editAdminFetchStore({
          data,
          callback: () => {
            history.push('/admin')
          },
        })
      : createPharmacy({
          data,
          callback: () => {
            history.push('/admin')
          },
        })
  }

  const history = useHistory()

  useEffect(() => {
    if (ADMIN_EMAILS.includes(userInfo.email)) {
      fetchDrivers()
      fetchStores()
    } else {
      history.push('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isAddStoreForm, setIsAddStoreForm] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [storeNumber, setStoreNumber] = useState('')
  const [storeName, setStoreName] = useState('')
  const [streetAddress, setStreetAddress] = useState('')
  const [storePhone, setStorePhone] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [state, setState] = useState('')
  const [programId, setProgramId] = useState(0)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [isBarcodeScanningRequired, setBarcodeScanningRequired] = useState(true)
  const [isNarcoticsCheckRequired, setNarcoticsCheckRequired] = useState(true)
  const [isScriptRunnerCard, setIsScriptRunnerCard] = useState(false)
  const [allowSplitDropoffs, setAllowSplitDropoffs] = useState(false)
  const [customerProfileMatch, setCustomerProfileMatch] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [isCollectPayments, setIsCollectPayments] = useState(false)
  const [selectedStore, setSelectedStore] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedDriverForModal, setSelectedDriverForModal] = useState(null)
  const [selectedDrivers, setSelectedDrivers] = useState([])
  const [showDriverForm, setShowDriverForm] = useState(false)
  const [driverSearchTerm, setDriverSearchTerm] = useState('')
  const [additionalEmailForInvoice, setAdditionalEmailForInvoice] = useState('')
  const [additionalEmailForCashRecovery, setAdditionalEmailForCashRecovery] =
    useState('')
  const [driverModel, setDriverModel] = useState('Flat Rate')
  const [flatRate, setFlatRate] = useState('')
  const [distanceRates, setDistanceRates] = useState([
    {distanceFrom: '', distanceTo: '', rate: ''},
  ])
  const [costAddedPercentage, setCostAddedPercentage] = useState('')
  const [setupFees, setSetupFees] = useState([{date: '', amount: ''}])
  const [showSetupFees, setShowSetupFees] = useState(false)
  const [recurringFeeAmount, setRecurringFeeAmount] = useState('')
  const [recurringFeeFrequency, setRecurringFeeFrequency] = useState('')
  const [softwareModel, setSoftwareModel] = useState('Legacy')
  const [tier, setTier] = useState({
    plan: 'Custom',
    monthlyPrice: '',
    pricePerStop: '',
    stopsFrom: '',
    stopsTo: '',
  })
  const [hasRecurringFeeError, setHasRecurringFeeError] = useState(false)
  const [postalCodeError, setPostalCodeError] = useState('')

  const loadingSpinner = (
    <Loading
      message={downloadingReport ? 'We are working on your download' : ''}
    />
  )

  const clearForm = () => {
    setStoreNumber('')
    setStoreName('')
    setStreetAddress('')
    setStorePhone('')
    setCity('')
    setPostalCode('')
    setState('')
    setProgramId(0)
    setEmail('')
    setFirstName('')
    setLastName('')
    setBarcodeScanningRequired(true)
    setNarcoticsCheckRequired(true)
    setDriverModel('Flat Rate')
    setFlatRate('')
    setDistanceRates([{distanceFrom: '', distanceTo: '', rate: ''}])
    setCostAddedPercentage('')
    setSetupFees([{date: '', amount: ''}])
    setShowSetupFees(false)
    setRecurringFeeAmount('')
    setRecurringFeeFrequency('')
    setSoftwareModel('Legacy')
    setTier({
      plan: 'Custom',
      monthlyPrice: '',
      pricePerStop: '',
      stopsFrom: '',
      stopsTo: '',
    })
  }

  const onFormEditClick = () => {
    selectedStore &&
      adminFetchStore({
        storeId: selectedStore.id,
        callback: (adminStoreInfo) => {
          setIsAddStoreForm(true)
          setIsEdit(true)
          setStoreNumber(adminStoreInfo?.storeNumber)
          setStoreName(adminStoreInfo?.storeName)
          setStreetAddress(adminStoreInfo?.streetAddress)
          setStorePhone(adminStoreInfo?.storePhone)
          setCity(adminStoreInfo?.city)
          setPostalCode(adminStoreInfo?.postalCode)
          setState(adminStoreInfo?.province)
          setProgramId(adminStoreInfo?.programId)
          setEmail(adminStoreInfo?.email)
          setFirstName(adminStoreInfo?.firstName)
          setLastName(adminStoreInfo?.lastName)
          setBarcodeScanningRequired(adminStoreInfo?.isBarcodeScanningRequired)
          setNarcoticsCheckRequired(adminStoreInfo?.isNarcoticsCheckRequired)
          setAllowSplitDropoffs(adminStoreInfo?.allowSplitDropoffs)
          setCustomerProfileMatch(adminStoreInfo?.enableCustomerMatching)
          setDriverModel(adminStoreInfo?.driverModel || 'Flat Rate')
          setFlatRate(adminStoreInfo?.flatRate || '')
          setDistanceRates(
            adminStoreInfo?.distanceRates || [{from: '', to: '', rate: ''}],
          )
          setCostAddedPercentage(adminStoreInfo?.costAddedPercentage || '')
          setSetupFees(adminStoreInfo?.setupFees || [{date: '', amount: ''}])
          setShowSetupFees(!!adminStoreInfo?.setupFees?.length)
          setRecurringFeeAmount(adminStoreInfo?.recurringFee?.amount || '')
          setRecurringFeeFrequency(
            adminStoreInfo?.recurringFee?.frequency || '',
          )
          setSoftwareModel(adminStoreInfo?.softwareModel || 'Legacy')
          setTier(adminStoreInfo?.tier || {})
          setAdditionalEmailForInvoice(
            adminStoreInfo?.additionalInvoiceEmail || '',
          )
          setAdditionalEmailForCashRecovery(
            adminStoreInfo?.additionalCashRecoveryEmail || '',
          )
        },
      })
  }

  const onFormAddClick = () => {
    clearForm()
    setIsEdit(false)
    setIsAddStoreForm(true)
  }

  const handleActionsClick = (driver) => {
    setSelectedDriverForModal(driver)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setSelectedDriverForModal(null)
  }

  const handleDriverSelect = (driverId) => {
    setSelectedDrivers((prev) =>
      prev.includes(driverId)
        ? prev.filter((id) => id !== driverId)
        : [...prev, driverId],
    )
  }

  const handleDeleteSelected = () => {
    if (
      window.confirm(
        `Are you sure you want to delete ${selectedDrivers.length} driver(s)?`,
      )
    ) {
      deleteDrivers(selectedDrivers.map(String))
      setSelectedDrivers([])
    }
  }

  const filteredDrivers = drivers.filter((driver) => {
    const searchLower = driverSearchTerm.toLowerCase()
    return (
      driver.driverName?.toLowerCase().includes(searchLower) ||
      driver.emailAddress?.toLowerCase().includes(searchLower) ||
      driver.driverPhone?.toLowerCase().includes(searchLower)
    )
  })

  const handlePostalCodeChange = (e) => {
    const value = e.target.value
    setPostalCode(value)

    if (value && !isValidPostalCode(value)) {
      setPostalCodeError(
        'Please enter a valid Canadian postal code (e.g., V6G-2G4)',
      )
    } else {
      setPostalCodeError('')
    }
  }

  const isFormValidCheck = () => {
    return isFormValid({
      storeNumber,
      storeName,
      streetAddress,
      storePhone,
      city,
      postalCode,
      state,
      firstName,
      lastName,
      isScriptRunnerCard,
      email,
      programId,
      driverModel,
      flatRate,
      distanceRates,
      costAddedPercentage,
      softwareModel,
      tier,
      setupFees,
      showSetupFees,
    })
  }

  if (loading) return loadingSpinner

  return (
    <>
      {currentAdminPage === ADMIN_TABS.stores && (
        <>
          {isAddStoreForm ? (
            <>
              <BackLink onClick={() => setIsAddStoreForm(false)}>
                {' < Back'}
              </BackLink>
              <AdminContainer>
                <AdminContainerCard>
                  <Form>
                    <div>
                      <TextFieldLeft>
                        <TextField
                          type="text"
                          label="Store Number"
                          name="storeNumber"
                          id="storeNumber"
                          required
                          onChange={(e) => setStoreNumber(e.target.value)}
                          value={storeNumber}
                        />
                      </TextFieldLeft>
                      <TextFieldRight>
                        <TextField
                          type="text"
                          label="Store Name"
                          name="storeName"
                          id="storeName"
                          required
                          onChange={(e) => setStoreName(e.target.value)}
                          value={storeName}
                        />
                      </TextFieldRight>
                      <TextField
                        type="text"
                        label="Street Address"
                        name="streetAddress"
                        id="streetAddress"
                        required
                        onChange={(e) => setStreetAddress(e.target.value)}
                        value={streetAddress}
                      />
                      <TextField
                        type="text"
                        label="City"
                        name="city"
                        id="city"
                        required
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                      />
                      <TextField
                        type="text"
                        label="Postal Code"
                        name="postalCode"
                        id="postalCode"
                        required
                        onChange={handlePostalCodeChange}
                        value={postalCode}
                      />
                      <ErrorMessage>{postalCodeError}</ErrorMessage>
                      <StyledLabel>Province</StyledLabel>
                      <select
                        name="Province"
                        style={{
                          ...dropdownStyle,
                          width: '100%',
                          padding: '18.5px 14px',
                        }}
                        required
                        onChange={(e) => setState(e.target.value)}
                        value={state}>
                        {PROVINCES.map((item, index) => (
                          <option key={`province-option-${index}`} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <TextField
                        type="text"
                        label="Store Phone"
                        name="storePhone"
                        id="storePhone"
                        required
                        onChange={(e) => setStorePhone(e.target.value)}
                        value={storePhone}
                      />
                      <TextFieldLeft>
                        <TextField
                          type="text"
                          label="First Name"
                          name="firstName"
                          id="firstName"
                          required
                          onChange={(e) => setFirstName(e.target.value)}
                          value={firstName}
                        />
                      </TextFieldLeft>
                      <TextFieldRight>
                        <TextField
                          type="text"
                          label="Last Name"
                          name="lastName"
                          id="lastName"
                          required
                          onChange={(e) => setLastName(e.target.value)}
                          value={lastName}
                        />
                      </TextFieldRight>
                      <br></br>
                      <div style={{marginTop: '40px'}}>
                        <div style={{display: 'inline-block'}}>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              setBarcodeScanningRequired(e.target.checked)
                            }
                            checked={isBarcodeScanningRequired}
                          />
                          <b>Force Barcode Scanning</b>
                        </div>
                        <div
                          style={{marginLeft: '38px', display: 'inline-block'}}>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              setNarcoticsCheckRequired(e.target.checked)
                            }
                            checked={isNarcoticsCheckRequired}
                          />
                          <b>Force Narcotics / CS proof of delivery</b>
                        </div>
                      </div>
                      <br />
                      <div style={{marginTop: '40px'}}>
                        <div style={{display: 'inline-block'}}>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              setAllowSplitDropoffs(e.target.checked)
                            }
                            checked={allowSplitDropoffs}
                          />
                          <b>Enable Driver Task Sharing</b>
                        </div>
                        <div
                          style={{marginLeft: '20px', display: 'inline-block'}}>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              setCustomerProfileMatch(e.target.checked)
                            }
                            checked={customerProfileMatch}
                          />
                          <b>Enable Customer Profile Matching</b>
                        </div>
                      </div>
                      <br />

                      <div style={{marginTop: '20px'}}>
                        <div style={{display: 'inline-block'}}>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              setIsScriptRunnerCard(e.target.checked)
                            }
                            checked={isScriptRunnerCard}
                          />
                          <b>Create ScriptRunner Card</b>
                        </div>
                        <div
                          style={{marginLeft: '27px', display: 'inline-block'}}>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              setIsCollectPayments(e.target.checked)
                            }
                          />
                          <b>Collect Payments From Customers</b>
                        </div>
                      </div>
                      <br />
                      {isScriptRunnerCard && (
                        <>
                          <br />
                          <TextFieldLeft>
                            <TextField
                              type="text"
                              label="Email"
                              name="email"
                              id="email"
                              required
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                            />
                          </TextFieldLeft>
                          <TextFieldRight>
                            <TextField
                              type="number"
                              label="Program ID"
                              name="programId"
                              id="programId"
                              required
                              onChange={(e) => setProgramId(e.target.value)}
                              value={programId}
                            />
                          </TextFieldRight>
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                      }}>
                      <InvoiceDataForm
                        softwareModel={softwareModel}
                        setSoftwareModel={setSoftwareModel}
                        tier={tier}
                        setTier={setTier}
                      />
                      <DriverModelForm
                        driverModel={driverModel}
                        setDriverModel={setDriverModel}
                        flatRate={flatRate}
                        setFlatRate={setFlatRate}
                        distanceRates={distanceRates}
                        setDistanceRates={setDistanceRates}
                        costAddedPercentage={costAddedPercentage}
                        setCostAddedPercentage={setCostAddedPercentage}
                      />
                      <SetupFeeForm
                        setupFees={setupFees}
                        setSetupFees={setSetupFees}
                        showSetupFees={showSetupFees}
                        setShowSetupFees={setShowSetupFees}
                      />
                      <RecurringFees
                        recurringFeeAmount={recurringFeeAmount}
                        setRecurringFeeAmount={setRecurringFeeAmount}
                        recurringFeeFrequency={recurringFeeFrequency}
                        setRecurringFeeFrequency={setRecurringFeeFrequency}
                        setHasRecurringFeeError={setHasRecurringFeeError}
                      />
                      <TextField
                        type="email"
                        label="Additional Email Address for Invoice"
                        name="additionalEmailForInvoice"
                        id="additionalEmailForInvoice"
                        onChange={(e) =>
                          setAdditionalEmailForInvoice(e.target.value)
                        }
                        value={additionalEmailForInvoice}
                      />
                      <TextField
                        type="email"
                        label="Additional Email Address for Cash Recovery"
                        name="additionalEmailForCashRecovery"
                        id="additionalEmailForCashRecovery"
                        onChange={(e) =>
                          setAdditionalEmailForCashRecovery(e.target.value)
                        }
                        value={additionalEmailForCashRecovery}
                      />
                    </div>
                    <PrimaryButton
                      style={{marginTop: '32px'}}
                      onClick={onSubmit}
                      disabled={hasRecurringFeeError || !isFormValidCheck()}>
                      {isEdit ? 'Confirm' : 'Create Store'}
                    </PrimaryButton>
                  </Form>
                </AdminContainerCard>
              </AdminContainer>
            </>
          ) : (
            <>
              <AdminTitle style={{padding: '24px 48px', width: '100%'}}>
                Manage Stores
              </AdminTitle>
              <AdminContainer style={{padding: '0px 48px'}}>
                <Container>
                  <label
                    style={{
                      display: 'block',
                      marginRight: '15px',
                      marginBottom: '10px',
                      color: theme.colors.label,
                    }}>
                    <b>Store Name</b>
                  </label>
                  <select
                    name="Stores"
                    style={dropdownStyle}
                    onChange={(e) => setSelectedStore(stores[e.target.value])}>
                    <option value="" selected>
                      Choose Store
                    </option>
                    {stores.map((item, index) => (
                      <option key={`stores-option-${index}`} value={index}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <div style={{float: 'right'}}>
                    <PrimaryButton
                      style={{
                        width: '150px',
                        marginRight: '8px',
                        marginLeft: '24px',
                      }}
                      onClick={onFormAddClick}>
                      Add Store
                    </PrimaryButton>
                    <PrimaryButton
                      style={{width: '150px', float: 'right'}}
                      onClick={onFormEditClick}
                      disabled={!selectedStore}>
                      Edit Store
                    </PrimaryButton>
                  </div>
                  <AdminContainerCard>
                    <DateFilterContainer>
                      <DateFilter
                        buttonText="Generate Report"
                        info={selectedStore}
                        action="store"
                        disabled={!selectedStore}
                      />
                    </DateFilterContainer>
                  </AdminContainerCard>
                </Container>
              </AdminContainer>
            </>
          )}
        </>
      )}
      {currentAdminPage === ADMIN_TABS.drivers && (
        <AdminContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '30px',
            }}>
            {showDriverForm ? (
              <AdminTitle>Create Driver</AdminTitle>
            ) : (
              <AdminTitle>Manage Drivers</AdminTitle>
            )}

            {!showDriverForm && (
              <PrimaryButton
                style={{width: '200px', height: '50px', fontWeight: 'bold'}}
                onClick={() => setShowDriverForm(true)}>
                <Add size={25} style={{marginRight: '10px'}} />
                ADD DRIVER
              </PrimaryButton>
            )}
          </div>
          {showDriverForm ? (
            <AdminContainerCard>
              <CreateDriverForm
                setShowDriverForm={setShowDriverForm}
                addDriver={addDriver}
              />
            </AdminContainerCard>
          ) : (
            <>
              <SearchAndActionsContainer>
                <Search
                  value={driverSearchTerm}
                  onChange={setDriverSearchTerm}
                  placeholder="Search drivers by name, email or phone number..."
                  ariaLabel="Search drivers by name, email or phone number"
                />
                <SecondaryDangerButton
                  onClick={handleDeleteSelected}
                  style={{width: '200px', height: '50px', fontWeight: 'bold'}}
                  disabled={selectedDrivers.length === 0}>
                  <Trash size={20} style={{marginRight: '10px'}} />
                  DELETE
                </SecondaryDangerButton>
              </SearchAndActionsContainer>
              <DriversGrid>
                {filteredDrivers.map((driver) => (
                  <DriverCard
                    key={driver.driverId}
                    driver={driver}
                    selectedDrivers={selectedDrivers}
                    handleDriverSelect={handleDriverSelect}
                    handleActionsClick={handleActionsClick}
                  />
                ))}
              </DriversGrid>
              <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="driver-actions-modal">
                <ModalContent>
                  <CloseButton onClick={handleCloseModal}>
                    <IoCloseOutline size={25} />
                  </CloseButton>
                  <ModalHeader>
                    <h2>{selectedDriverForModal?.driverName}</h2>
                  </ModalHeader>
                  <DateFilter
                    buttonText="Generate Report"
                    info={selectedDriverForModal}
                    action="driver"
                  />
                </ModalContent>
              </Modal>
            </>
          )}
        </AdminContainer>
      )}
    </>
  )
}

const mapStateToProps = ({
  app: {loading, currentAdminPage},
  user: {userInfo},
  admin: {drivers, stores, downloadingReport},
}) => {
  return {
    loading,
    currentAdminPage,
    userInfo,
    drivers,
    stores,
    downloadingReport,
  }
}

const mapDispatchToProps = (dispatch) => ({
  createPharmacy: (pharmacy) => dispatch(createPharmacy(pharmacy)),
  fetchDrivers: (data) => dispatch(fetchDrivers(data)),
  fetchStores: (data) => dispatch(fetchStores(data)),
  adminFetchStore: (data) => dispatch(adminFetchStore({data})),
  editAdminFetchStore: (data) => dispatch(editAdminFetchStore({data})),
  addDriver: (data) => dispatch(addDriver(data)),
  deleteDrivers: (ids) => dispatch(deleteDrivers(ids)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Admin)

const Form = styled.form`
  & label {
    display: grid;
    margin-top: 15px;
    font-size: large;
  }
  & input {
    font-size: large;
  }
`

const Container = styled.div`
  align-self: baseline;
`

const DateFilterContainer = styled.div`
  margin: 2rem 2.5rem;
`

const AdminTitle = styled.h3`
  font-size: 24px;
  float: left;
`

const BackLink = styled.p`
  font-weight: bold;
  color: ${theme.colors.primary};
  cursor: pointer;
  align-self: baseline;
  margin: 54px 0 0 54px;
`

const AdminContainerCard = styled.div`
  border-radius: 5px;
  background-color: white;
  padding: 26px;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.07);
  min-width: 350px;
  margin: 24px 0;
`

const AdminContainer = styled.div`
  padding: 24px 48px;
  width: 100%;
`

const TextFieldLeft = styled.div`
  display: inline-block;
  width: 45%;
`
const TextFieldRight = styled.div`
  display: inline-block;
  width: 45%;
  float: right;
`

const StyledLabel = styled(InputLabel)`
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.label};
  line-height: 2rem;
`
const dropdownStyle = {
  padding: '14px 12px',
  width: '300px',
  border: '1px solid #E0E0E0',
  color: theme.colors.label,
  borderRadius: '8px',
  backgroundColor: '#FFFFFF',
  fontSize: '16px',
  cursor: 'pointer',
  appearance: 'none',
  backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1L7 7L13 1' stroke='%23666666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e")`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 16px center',
  transition: 'border-color 0.2s ease',
  '&:hover': {
    borderColor: '#C4D5EE',
  },
  '&:focus': {
    outline: 'none',
    borderColor: theme.colors.primary,
    boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.1)',
  },
}

const DriversGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
  margin-top: 24px;
`

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 25px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  outline: none;
  position: relative;
`

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: ${({theme}) => theme.colors.label};
  z-index: 1;

  &:hover {
    color: ${({theme}) => theme.colors.primary};
  }
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 24px;

  h3 {
    margin: 0;
  }
`

const SearchAndActionsContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;

  ${Search} {
    max-width: 400px;
  }
`

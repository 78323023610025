import {
  SHOW_ERROR,
  SILENCE_ERROR,
  START_LOADING,
  STOP_LOADING,
  TOGGLE_ADMIN_PAGE,
} from '../actions/types'

function deliveriesDataReducers(state = {}, action = {}) {
  switch (action.type) {
    case SHOW_ERROR:
      return {...state, error: action.payload}
    case SILENCE_ERROR:
      return {...state, error: undefined}
    case START_LOADING:
      return {...state, loading: true}
    case STOP_LOADING:
      return {...state, loading: false}
    case TOGGLE_ADMIN_PAGE:
      return {...state, currentAdminPage: action.payload}
    default:
      return state
  }
}

export default deliveriesDataReducers

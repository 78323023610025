import React from 'react'
import {DialogActions, DialogContent} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import styled from 'styled-components'
import {PrimaryButton, SecondaryButton} from '../../Button'
import DestinationCard from './DestinationCard'

const CustomDialogTitle = styled.h2`
  text-align: center;
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: 2.4rem;
  font-weight: 700;
  margin: 15px 0px 10px;
`

const ConfirmAddressModal = ({
  handleClose,
  updateSelectedAddress,
  setFormSubmitted,
  destinations = [],
  getValues,
  handleSubmitPrescription,
  updatePrescription,
  orderId,
  setValue,
}) => {
  const classes = useStyles()
  const [selectedDestination, setSelectedDestination] = React.useState(null)
  const values = getValues()

  const saveNewPrescription = () => {
    const {prescriptions} = values
    const prescriptionWithNumericPrices = prescriptions.map((prescription) => {
      prescription.price = prescription.price.toString().replace(/\$/, '')
      return prescription
    })

    const selectedDestinationAddress = destinations.find(
      (dest) => dest.googlePlaceId === selectedDestination,
    )?.address.description

    const formValues = {
      ...values,
      prescriptions: prescriptionWithNumericPrices,
      customerAddress: selectedDestinationAddress,
      shippingAddress: selectedDestinationAddress,
    }

    orderId
      ? updatePrescription(formValues)
      : handleSubmitPrescription(formValues)
  }

  const handleDestinationSelect = (googlePlaceId) => {
    setSelectedDestination(googlePlaceId)
    const selectedAddress = destinations.find(
      (dest) => dest.googlePlaceId === googlePlaceId,
    )?.address.description

    if (selectedAddress) {
      setValue('shippingAddress', selectedAddress, {
        shouldValidate: true,
        shouldDirty: true,
      })
    }
  }

  return (
    <div id="confirmation-dialog" className={classes.modal}>
      <CustomDialogTitle>Confirm Address</CustomDialogTitle>
      <DialogContent className={classes.destinationsContainer}>
        {destinations.map(({googlePlaceId, address}) => (
          <DestinationCard
            key={googlePlaceId}
            googlePlaceId={googlePlaceId}
            address={address.description}
            isSelected={selectedDestination === googlePlaceId}
            onSelect={handleDestinationSelect}
          />
        ))}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <SecondaryButton
          id="go-back-button"
          className={classes.closeButton}
          onClick={handleClose}>
          Go Back
        </SecondaryButton>
        <PrimaryButton
          id="confirm-button"
          type="button"
          className={classes.successButton}
          onClick={async () => {
            if (selectedDestination) {
              try {
                const selectedAddress = destinations.find(
                  (dest) => dest.googlePlaceId === selectedDestination,
                )?.address.description

                if (selectedAddress) {
                  setValue('shippingAddress', selectedAddress)
                  setValue('customerAddress', selectedAddress)

                  updateSelectedAddress(selectedDestination)
                  setFormSubmitted(true)
                  saveNewPrescription()
                  handleClose()
                }
              } catch (error) {
                console.error(
                  'Error during address selection and form submission:',
                  error,
                )
              }
            }
          }}>
          Confirm
        </PrimaryButton>
      </DialogActions>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  modal: {
    height: '75vh',
    maxHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  dialogActions: {
    justifyContent: 'space-between',
    padding: '1rem 1rem 1.5rem',
  },
  successButton: {
    flexGrow: 2,
    width: '23.7rem',
  },
  closeButton: {
    flexGrow: 1,
    width: '16rem',
    marginRight: '2rem',
    padding: '15px 0',
    boxSizing: 'border-box',
    borderRadius: '5px',
    justifyContent: 'center',
  },
  destinationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '1rem',
  },
}))

export default ConfirmAddressModal

import {
  CLEAR_ADDRESSES,
  GET_ADDRESS_VALIDATION,
  GET_ADDRESS_VALIDATION_SUCCESS,
} from './types'

const validateAddress = (payload) => {
  return {type: GET_ADDRESS_VALIDATION, payload}
}
const validateAddressSuccess = (payload) => {
  return {type: GET_ADDRESS_VALIDATION_SUCCESS, payload}
}

const clearAddresses = () => {
  return {type: CLEAR_ADDRESSES}
}

export {validateAddress, validateAddressSuccess, clearAddresses}

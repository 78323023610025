import {put, select, takeEvery} from 'redux-saga/effects'
import {
  CLEAR_ADDRESSES,
  GET_ADDRESS_VALIDATION,
  RESET_ADDRESS_VALIDATION,
  START_LOADING,
  STOP_LOADING,
} from '../actions/types'
import {validateAddressSuccess, showError} from '../../redux/actions'
import {request} from '../../utils/api'
import {getToken} from '../utils'
import {API_ADDRESS_VALIDATION_ENDPOINT} from '../../constants'

export function* validateAddress({payload}) {
  const token = yield select(getToken)

  const reqOpt = {
    method: 'POST',
    url: `${API_ADDRESS_VALIDATION_ENDPOINT}`,
    token,
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    yield put({type: START_LOADING})
    const result = yield request(reqOpt)
    yield put(validateAddressSuccess(result))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* clearAddresses() {
  yield put({type: RESET_ADDRESS_VALIDATION})
}

// Watcher saga
export function* watchGetVoiceInsights() {
  yield takeEvery(GET_ADDRESS_VALIDATION, validateAddress)
  yield takeEvery(CLEAR_ADDRESSES, clearAddresses)
}
export default watchGetVoiceInsights
